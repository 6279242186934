<!--
 * @Author: your name
 * @Date: 2020-11-24 14:55:24
 * @LastEditTime: 2021-04-06 15:41:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\createInverter.vue
-->
<template>
    <div class="addCity box-create">
        <p style=""></p>
        <div class="box-title">{{$t('project.heatpump-add')}}</div>
        <el-form ref="form" :model="form" label-width="auto" label-position='right'>
            <el-row :gutter="24">
                <el-col :span="10" :offset="1">
                    <!-- <el-divider content-position="center">{{$t('project.regular-data')}}</el-divider> -->
                     <el-form-item :label="$t('heatpump.data-type')"  prop="name" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.name" ></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('heatpump.data.Length')+'(mm)'"  prop="length" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.length" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('heatpump.data.Width')+'(mm)'" prop="width" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.width" type="number" v-number></el-input>
                    </el-form-item>
                   <el-form-item :label="$t('heatpump.data.Height')+'(mm)'" prop="height" :rules="[{ required: false, message: $t('remind.not-null')}]">
                        <el-input v-model="form.height" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('heatpump.data.Dry-contact')+'(pair)'" prop="dryContact" :rules="[{ required: true, message: $t('remind.not-null')}]">
                        <el-input v-model="form.dryContact" type="number" v-number></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('heatpump.data.Workmode')" prop="workMode" :rules="[{ required: true, message: $t('remind.not-null')}]" >
                        <el-input v-model="form.workMode"></el-input>
                    </el-form-item>
                     <!-- <el-form-item :label="$t('heatpump.data.Workmode')" prop="workMode" :rules="[{ required: true, message: $t('remind.not-null')}]" >
                        <el-select v-model="form.workMode" >
                            <el-option label="Disable" value="0" > </el-option>
                            <el-option label="Manual" value="1"> </el-option>
                            <el-option label="SmartSave"  value="2"></el-option>
                        </el-select>
                    </el-form-item> -->
                </el-col>
                
       
            </el-row>
        </el-form>
        <div class="btn-wrap">
            <el-button type="primary" @click="saveForm('form')">{{$t('userInfo.alter')}}</el-button>
            <el-button @click="()=>{$router.push({path:'/adminHeatPump'})}">{{$t('uerInfo.back')}}</el-button>
        </div>
    </div>
</template>

<script>
import baseFile from '../../components/common/baseFileInput'
export default {
    components: {
        baseFile
    },
    props: {

    },
    data() {
        return {
            form:{
            },
            heatPumpId: this.$route.query.id,
            regions:[],
            countrys:[],
            defaultGridIDs:[5,6,13],
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        if(this.heatPumpId){
            this.theatpumpGetById()
        }
        

    },
    methods: {
        changeNomPowerform(){
            let nomPowerTo = this.form.nomPowerTo && parseInt(this.form.nomPowerTo)
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            this.form.nomPowerFrom = nomPowerFrom > nomPowerTo ? nomPowerTo - 1 :nomPowerFrom
        },
        changeNomPowerTo(){
            let nomPowerTo = this.form.nomPowerTo? parseInt(this.form.nomPowerTo) : ''
            let nomPowerFrom = this.form.nomPowerFrom ? parseInt(this.form.nomPowerFrom) : ''
            if((nomPowerTo <= nomPowerFrom) && nomPowerFrom && nomPowerTo){
                this.$message({
                    message: this.$t('nomPowerFrom.big'),
                    type: 'warning'
                });
                this.form.nomPowerTo = parseInt(nomPowerFrom) + 1
                nomPowerTo = parseInt(nomPowerFrom) + 1
            }
            if(nomPowerTo >150 || nomPowerTo  == 150){
                // console.log(this.form.nomPowerTo,'?');
                this.form.nomPowerTo = 149
            }else if(nomPowerTo <=100){
                this.form.nomPowerTo = 100
            }
        },
        /*获取详情数据*/
        async theatpumpGetById(){
            let res = await this.API.theatpumpGetById({
                id: this.heatPumpId
            })

            if(this.defaultGridIDs.indexOf(res.defaultGridID)<0){
                res.defaultGridID = ''
            }
            this.form = res
        },

        
      

        /*保存提交*/
        saveForm(formName){
            let $this = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if($this.heatPumpId){
                        $this.update($this.form)
                    }else {
                        $this.save($this.form)
                    }
                } else {
                    return false;
                }
            });
        },

        /*更新*/
        async update(data){
            let res = await this.API.theatpumpUpdate(data)
            this.showMessage(res)

        },

        /*创建*/
        async save(data){
            let res = await this.API.theatpumpSave(data)
            this.showMessage(res)
        },

        showMessage(res){
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.$router.push({path: '/adminHeatPump'});
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },


    },
};
</script>

<style lang="less">
.box-create{
    .box-title{
        margin-bottom: 10px;
        font-weight: 700;
    }
    .el-form{
        .el-divider{
            white-space: nowrap;
        }
        .el-input-group__append, .el-input-group__prepend{
            padding: 0;
            width: 50px;
            text-align: center;
            font-size: 12px;
        }
        .el-input-group+.el-input-group{
            margin-left:10px;
        }
        .el-input-group.inline{
            width:112px;
        }
        .el-col-11,.el-col-2{
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
        .el-col-2{
            text-align: center;
        }
    }
}
    
</style>

